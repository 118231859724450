import React from "react";
import Hero from "../components/hero";
import MobileNav from "../components/mobile-nav"
import Footer from "../components/footer"
import SiteMetaData from "../components/sitemeta";
import teamStyles from "../components/teams.module.scss";
import "../styles/layout.scss";
import eventThumb from "../images/events/events-brunch.jpeg";
import halloweenParty from "../images/events/halloween-party.jpeg";
import summersocial from "../images/events/summersocial.jpeg";

const Layout = ({ children }) => (
  <div>
    {children}
    <Footer />
    <MobileNav />
  </div>
)

const EventsPage = () => {
  return (
    <Layout>
      <div className="events" style={({ "backgroundColor": "black" })}>
        <SiteMetaData
          title={`Upcoming Bachata & Salsa Events | RF Dance`}
          description="Get ready to move your hips and feel the rhythm with our upcoming Bachata and Salsa events! Join us now for an unforgettable experience!"
          pathname={`events`} />
        <Hero
          titleRed={`RF`}
          titleWhite={`Events`} />

        <div className={`${teamStyles.auditions} content-block`}>
          <div className={`${teamStyles.container} container`}>
            <div className="columns">
              {/*  {Endless Summer Social} */}
              {/* <div className="column is-half" style={{ flex: "min-content", paddingLeft: "2em", paddingRight: "2em" }}>
                <a href="
https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=47&sView=day&sLoc=0" target="_blank">
                  <img style={{ width: "100%" }} src={summersocial} alt="RF Halloween" />
                </a>
                <h3 style={{ marginBottom: "0.5em", textAlign: "center" }}>Endless Summer Social</h3>
                <div className="columns">
                  <div className="column is-half" style={{
                    textAlign: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    flex: "min-content"
                  }}>
                    <h4 style={{ fontSize: "1em", textTransform: "uppercase", marginBottom: "6px", marginTop: "10px" }}>Date and Time</h4>
                    <p style={{ marginTop: 0, textAlign: "left" }}>September 29th, 2023, from 10 pm - 2 am PDT</p>
                  </div>
                  <div className="column is-half" style={{
                    textAlign: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    flex: "min-content"
                  }}>
                    <h4 style={{ fontSize: "1em", textTransform: "uppercase", marginBottom: "6px", marginTop: "10px" }}>Location</h4>
                    <p style={{ marginTop: 0, textAlign: "left" }}>1517 N. Main st, Santa Ana, CA 92701</p>
                  </div>
                </div>
                <p style={{ marginTop: "2em", textAlign: "justify" }}>🌴🎶 Endless Summer Social at RF Dance Center 💃🎉 As the summer sun sets and the cool autumn breeze starts to roll in, we invite you to join us in celebrating the end of an incredible season with a night filled with rhythm, energy, and endless fun! On September 29th, 2023, from 10 pm - 2 am, RF Dance Center at 1517 N. Main St, Santa Ana Ca, 92701, is hosting the ultimate "Endless Summer Social". The night will kick off with an Latin dance class at 10 pm, perfect for beginners and seasoned dancers alike.</p>

                <p style={{ marginTop: "2em", textAlign: "justify" }}> The dress code? Think beachy and breezy! Don your favorite summer attire and let's bid farewell to the season in style.</p>
                <p style={{ marginTop: "2em", textAlign: "justify" }}>Pricing:
                  RF Members: FREE (must pre-register)
                  Pre-Sale (before 9/22) : $15
                  Regular Price: $20</p>
                <p style={{ marginTop: 0, textAlign: "justify" }}>The "Endless Summer Social" at RF Dance Center is more than just a party, it's a celebration of life, love, and the joy of dancing. We can't wait to see you there! 🌅🍹🌊</p>
              </div> */}
              {/* <div className="column is-half" style={{ flex: "min-content", paddingLeft: "2em", paddingRight: "2em" }}>
                <a href="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=48&sView=day&sLoc=0" target="_blank">
                  <img style={{ width: "100%" }} src={halloweenParty} alt="RF Halloween" />
                </a>
                <h3 style={{ marginBottom: "0.5em", textAlign: "center" }}>RF Halloween Social</h3>
                <div className="columns">
                  <div className="column is-half" style={{
                    textAlign: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    flex: "min-content"
                  }}>
                    <h4 style={{ fontSize: "1em", textTransform: "uppercase", marginBottom: "6px", marginTop: "10px" }}>Date and Time</h4>
                    <p style={{ marginTop: 0, textAlign: "left" }}>Sat October 21, 2023 , 2023, 9:00pm - 3:00am  PDT</p>
                  </div>
                  <div className="column is-half" style={{
                    textAlign: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    flex: "min-content"
                  }}>
                    <h4 style={{ fontSize: "1em", textTransform: "uppercase", marginBottom: "6px", marginTop: "10px" }}>Location</h4>
                    <p style={{ marginTop: 0, textAlign: "left" }}>1517 N. Main st, Santa Ana, CA 92701</p>
                  </div>
                </div>
                <p style={{ marginTop: "2em", textAlign: "justify" }}>Step into the reel world of horror as our Halloween Social Dance Party brings your favorite spine-tingling movies to life! On October 21st, prepare for a blockbuster night with star-studded guest artists, DJs spinning hauntingly good tunes, breathtaking dance performances that will send shivers down your spine, and an electrifying costume contest. Brace yourself for unexpected twists, bone-chilling surprises, and a night that will leave you screaming for more!</p>
                <p style={{ marginTop: 0, textAlign: "justify" }}>Dress up as your favorite character and be ready to take center stage in this cinematic experience you won't want to miss! Get ready for a night that is a movie, and you've just been cast.</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
};

export default EventsPage;